<template>
  <div>
    NotFound
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style scoped lang="scss">

</style>